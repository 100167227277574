import axios from "axios";

export const BYLAW_BASE_URL=process.env.REACT_APP_BYLAW_BASE_URL
export const CALLER_BASE_URL=process.env.REACT_APP_CALLER_BASE_URL


const GET_CONFIG = {
  headers: { Authorization: "Bearer abcd" },
  mode: "no-cors",
};


export const select_raw_corpus = async (corpus_id, selected) => {
  const config = {
    headers: {
      Authorization: "Bearer abcd",
      "Content-Type": "application/json",
    },
    method: "POST",
    data: JSON.stringify({
      corpus_id: corpus_id,
      selection: selected,
    }),
    mode: "no-cors",
  };
  try{
    const data = await axios(`${BYLAW_BASE_URL}/select_raw_corpus`, config);
    return data;
  }catch(err){
    console.error(err);
  }

};


export const save_selections_bylaw = async (d, category, property_id) => {
  const config = {
    headers: {
      Authorization: "Bearer abcd",
      "Content-Type": "application/json",
    },
    method: "POST",

    data: JSON.stringify({
      bylaw_ids: d["check"],
      category,
    }),
    mode: "no-cors",
  };
  const {data} = await axios(`${BYLAW_BASE_URL}/set_property_bylaws/${property_id}`, config);
  return data
}

export const save_selections_caller = async (d, category, property_id) => {
  const config = {
    headers: {
      Authorization: "Bearer abcd",
      "Content-Type": "application/json",
    },
    method: "POST",

    data: JSON.stringify({
      bylaw_ids: d["check"],
      category,
    }),
    mode: "no-cors",
  };
  const {data} = await axios(`${CALLER_BASE_URL}/set_caller_bylaws/${property_id}`, config);
  return data
}

export const get_property_pdf = async (property_id) => {
  const {data} = await  axios.get(`${BYLAW_BASE_URL}/get_property_pdf/${property_id}`, GET_CONFIG);
  return data;
}

export const get_bylaw_answers = async (property_id) => {
  const {data} = await  axios.get(`${BYLAW_BASE_URL}/${property_id}`, GET_CONFIG);
  return data;
}


export const get_caller_answers = async (property_id) => {
  const {data} = await  axios.get(`${CALLER_BASE_URL}/${property_id}`, GET_CONFIG);
  return data;
}

export const get_preset_bylaw = async (property_id, category) => {
  const {data} = await  axios.get(`${BYLAW_BASE_URL}/get_preset_bylaws/${property_id}/${category}`, GET_CONFIG);
  return data;
}

export const get_custom_bylaw = async (property_id, category) => {
  const {data} = await axios.get(`${BYLAW_BASE_URL}/get_custom_bylaws/${property_id}/${category}`, GET_CONFIG);
  return data;
}

export const get_preset_bylaw_categories = async (property_id) => {
  const {data} = await  axios.get(`${BYLAW_BASE_URL}/get_preset_bylaw_categories/${property_id}`, GET_CONFIG);
  return data;
}


export const get_property_corpus_summary = async (id, property_id) => {
  const {data} = await  axios.get(`${BYLAW_BASE_URL}/get_property_corpus_summary/${property_id}/${id}`, GET_CONFIG);
  return data;
}

export const get_property_corpus = async (id, property_id) => {
  const {data} = await  axios.get(`${BYLAW_BASE_URL}/get_property_corpus/${property_id}/${id}`, GET_CONFIG);
  return data;
}

export const insert_new_corpus = async (property_id, payload, category) => {
  const config = {
    headers: {
      Authorization: "Bearer abcd",
      "Content-Type": "application/json",
    },
    method: "POST",
    data: JSON.stringify({
      ...payload,
      property_id,
      category
    }),
    mode: "no-cors",
  };
  const {data} = await  axios(`${BYLAW_BASE_URL}/insert_new_corpus`, config);
  return data;
}


export const edit_property_corpus = async (corpus_id, new_text) => {
  const config = {
    headers: {
      Authorization: "Bearer abcd",
      "Content-Type": "application/json",
    },
    method: "POST",
    data: JSON.stringify({
      corpus_id,
      new_text
    }),
    mode: "no-cors",
  };
  const {data} = await  axios(`${BYLAW_BASE_URL}/update_corpus`, config);
  return data;
}


export const edit_caller_note = async (property_id, note_content) => {
  const config = {
    headers: {
      Authorization: "Bearer abcd",
      "Content-Type": "application/json",
    },
    method: "POST",
    data: JSON.stringify({
      note_content,
    }),
    mode: "no-cors",
  };
  const {data} = await  axios(`${CALLER_BASE_URL}/notes/${property_id}`, config);
  return data;
}

export const get_caller_note = async (property_id) => {
  const {data} = await  axios.get(`${CALLER_BASE_URL}/notes/${property_id}`, GET_CONFIG);
  return data;
}

export const get_client_bylaws = async (property_id) => {
  const {data} = await  axios.get(`${BYLAW_BASE_URL}/client_bylaws/${property_id}`, GET_CONFIG);
  return data;
}
